import Const from "./constants";

export const User = {
	set: (data) => {
		const { token, user_details } = data;
		user_details.token = token;
		const encoded = Buffer(JSON.stringify(user_details)).toString("hex");
		window.localStorage.setItem("USER", encoded);
	},

	setClient: (data) => {
		const { access_token, token, user_details = {}, id = null } = data;
		user_details.token = token || access_token;
		user_details.is_admin_loggedin_as_client = 1;
		user_details.id = id;
		const encoded = Buffer(JSON.stringify(user_details)).toString("hex");
		window.localStorage.setItem("CLIENT_USER", encoded);
	},

	get: () => {
		const user = window.localStorage.getItem("CLIENT_USER") || window.localStorage.getItem("USER");
		if (user != null) {
			const decoded = Buffer.from(user, "hex");
			return JSON.parse(decoded.toString());
		} else {
			return null;
		}
	},
	getByKey: (key) => {
		const data = window.localStorage.getItem(key);
		if (data != null) {
			const decoded = Buffer.from(data, "hex");
			return JSON.parse(decoded.toString());
		} else {
			return null;
		}
	},
	let: (data, key) => {
		const encoded = Buffer(JSON.stringify(data)).toString("hex");
		window.localStorage.setItem(key, encoded);
	},
	update: (data) => {
		const user = window.localStorage.getItem("USER");
		const decoded = Buffer.from(user, "hex");
		const decodedParsed = JSON.parse(decoded.toString());
		data.token = decodedParsed.token;
		const encoded = Buffer(JSON.stringify(data)).toString("hex");
		window.localStorage.setItem("USER", encoded);
	},
	token: () => {
		const user = window.localStorage.getItem("USER");
		if (user != null) {
			const decoded = Buffer.from(user, "hex");
			const decodedParsed = JSON.parse(decoded.toString());
			return decodedParsed.token;
		} else {
			return null;
		}
	},
	userName: () => {
		const user = window.localStorage.getItem("USER");
		if (user != null) {
			const decoded = Buffer.from(user, "hex");
			const decodedParsed = JSON.parse(decoded.toString());
			return `${decodedParsed.first_name} ${decodedParsed.last_name}`;
		} else {
			return null;
		}
	},
	userType: () => {
		const user = User.get();
		if (user != null) {
			const type = user.user_type.includes("C")
				? Const.EPLAN_USER_ROLES_CATEGORY.CLIENT
				: Const.EPLAN_USER_ROLES_CATEGORY.IRS;
			return type;
		} else {
			return null;
		}
	},
	loggedin: () => {
		const user = window.localStorage.getItem("USER");
		if (user != null) {
			const decoded = Buffer.from(user, "hex");
			const decodedParsed = JSON.parse(decoded.toString());
			// TODO: check expiry and auto-logout if necessary
			return decodedParsed.token != null;
		} else {
			return false;
		}
	},
	logout: () => {
		window.localStorage.clear();
		window.location.href = "/";
	},
	remove: (key) => {
		localStorage.removeItem(key);
	}
};

export const masterDataConfig = {
	set: (data) => {
		const encoded = Buffer(JSON.stringify(data)).toString("hex");
		window.localStorage.setItem("CONFIG", encoded);
	},
	get: () => {
		const config = window.localStorage.getItem("CONFIG");
		if (config != null) {
			const decoded = Buffer.from(config, "hex");
			return JSON.parse(decoded);
		} else {
			return null;
		}
	}
};
