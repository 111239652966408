const constants = {
	API_URL: process.env.API_URL || "http://localhost:3010",
	IRNVAGTH_API_URL: process.env.IRNVAGTH_API_URL || "https://orsapi-dev.quolam.com",
	IRNAVGATH_REDIRECT_URL: process.env.IRNAVGATH_REDIRECT_URL || "https://ors-dev.quolam.com/auth/redirect",
	LOGIN_PAGE_INFO: process.env.LOGIN_PAGE_INFO,
	SHOW_LANDING_MESSAGE: process.env.SHOW_LANDING_MESSAGE,
	VERSION: process.env.VERSION || "/v1",
	PAGING: {
		LIMIT: 10,
		INITIAL_PAGE: 1
	},
	DATE_FORMAT: {
		LONG_DATE: "YYYY-MM-DD HH:mm:ss",
		CLIENT_DATE: "DD-MMM-YY",// DD MMM YYYY 
		CLIENT_DATE_TIME: "YYYY-MM-DD HH:mm:ss",
		CLIENT_DATE_TIME_SECOND: "YYYY-MM-DD HH:mm:00",
		SERVER_DATE: "YYYY-MM-DD",
		QUERY_DATE: "MM/DD/YYYY",
		TIME: "hh:mm A",
		PROJECT_HISTORY_DATE: "DD MMM YY",
		REPORT_DATE: "YYYY-MM-DD"
	},
	ROLE: {
		ADMIN: "Administrator",
		SUPPORT: "Support Staff",
		PROJECT_MANAGER: "HO Project Manager",
		ASSIGNEE: "Assignee",
		SIGNATORY: "HOD"
	},
	PRODUCT_NAME: [
		{ bs_product_name: "Diesel/HSHSD/Gas oil (ISO 8217 grades DMX through DMB)" },
		{ bs_product_name: "Light Fuel Oil (LFO- ISO 8217 Grades RMA through RMD)" },
		{ bs_product_name: "LS HF HSD" },
		{ bs_product_name: "HFHSD ( HIGH FLASH HIGH SPEED DIESEL)." },
		{ bs_product_name: "Heavy Fuel Oil (HFO - ISO 8217 Grades RME through RMK)" },
		{ bs_product_name: "Liquefied Petroleum Gas (LPG – Propane)" },
		{ bs_product_name: "Liquefied Petroleum Gas (LPG – Butane)" },
		{ bs_product_name: "Liquefied Natural Gas (LNG)" },
		{ bs_product_name: "Methanol" },
		{ bs_product_name: "Ethanol" },
		{ bs_product_name: "VLSFO (Very Low Sulphur Furnace Oil)" },
		{ bs_product_name: "HSFO (High Sulphur Furnace Oil) for scrubber fitted vessels" },
		{ bs_product_name: "Bio fuel (blend)" },
		{ bs_product_name: "Light Diesel Oil (LDO)" }
	],
	SHIP_FLAG: [
		{ bs_flag_of_vessel: "INDIAN" },
		{ bs_flag_of_vessel: "ANTIGUA AND BARBUDA" },
		{ bs_flag_of_vessel: "BAHAMAS" },
		{ bs_flag_of_vessel: "BANGLADESH" },
		{ bs_flag_of_vessel: "BARBADOS" },
		{ bs_flag_of_vessel: "BELIZE" },
		{ bs_flag_of_vessel: "BRITISH" },
		{ bs_flag_of_vessel: "BULGARIA" },
		{ bs_flag_of_vessel: "CAMBODIA" },
		{ bs_flag_of_vessel: "CAMEROON" },
		{ bs_flag_of_vessel: "CHILE" },
		{ bs_flag_of_vessel: "CHINA" },
		{ bs_flag_of_vessel: "COMMONWEALTH OF DOMINICA" },
		{ bs_flag_of_vessel: "COMOROS" },
		{ bs_flag_of_vessel: "COOK ISLANDS" },
		{ bs_flag_of_vessel: "CYPRUS" },
		{ bs_flag_of_vessel: "ESTONIA" },
		{ bs_flag_of_vessel: "GABON" },
		{ bs_flag_of_vessel: "GEORGIA" },
		{ bs_flag_of_vessel: "GREECE" },
		{ bs_flag_of_vessel: "GREECE" },
		{ bs_flag_of_vessel: "HOLLAND" },
		{ bs_flag_of_vessel: "HONDURAS" },
		{ bs_flag_of_vessel: "HONGKONG" },
		{ bs_flag_of_vessel: "INDONESIA" },
		{ bs_flag_of_vessel: "ITALY" },
		{ bs_flag_of_vessel: "JORDAN" },
		{ bs_flag_of_vessel: "KIRIBATI" },
		{ bs_flag_of_vessel: "KUWAIT" },
		{ bs_flag_of_vessel: "LIBERIA" },
		{ bs_flag_of_vessel: "LUXEMBOURG" },
		{ bs_flag_of_vessel: "MALAYSIA" },
		{ bs_flag_of_vessel: "MALDIVES" },
		{ bs_flag_of_vessel: "MALTA" },
		{ bs_flag_of_vessel: "MARSHALL ISLANDS" },
		{ bs_flag_of_vessel: "MAURITIUS" },
		{ bs_flag_of_vessel: "MONGOLIA" },
		{ bs_flag_of_vessel: "MYANMAR" },
		{ bs_flag_of_vessel: "NIUE" },
		{ bs_flag_of_vessel: "NORWAY" },
		{ bs_flag_of_vessel: "PALAU" },
		{ bs_flag_of_vessel: "PANAMA" },
		{ bs_flag_of_vessel: "PHILIPPINES" },
		{ bs_flag_of_vessel: "QATAR" },
		{ bs_flag_of_vessel: "REPUBLIC OF EQUATORIAL GUINEA" },
		{ bs_flag_of_vessel: "REPUBLIC OF VANUATU" },
		{ bs_flag_of_vessel: "REPUBLIC OF PORTUGAL" },
		{ bs_flag_of_vessel: "SIERRA LEONE" },
		{ bs_flag_of_vessel: "SINGAPORE" },
		{ bs_flag_of_vessel: "SRI LANKA" },
		{ bs_flag_of_vessel: "ST. KITTS AND NEVIS" },
		{ bs_flag_of_vessel: "ST. VINCENT AND THE GRENADINES" },
		{ bs_flag_of_vessel: "SULTANATE OF OMAN" },
		{ bs_flag_of_vessel: "THAILAND" },
		{ bs_flag_of_vessel: "THE NETHERLANDS" },
		{ bs_flag_of_vessel: "THE UNITED STATES OF AMERICA" },
		{ bs_flag_of_vessel: "TOGO" },
		{ bs_flag_of_vessel: "TURKEY" },
		{ bs_flag_of_vessel: "TUVALU" },
		{ bs_flag_of_vessel: "U.A.E." },
		{ bs_flag_of_vessel: "UGANDA" },
		{ bs_flag_of_vessel: "UNITED REPUBLIC OF TANZANIA" },
		{ bs_flag_of_vessel: "VENEZUELA" },
		{ bs_flag_of_vessel: "VIETNAM" },
		{ bs_flag_of_vessel: "Others" },
	],
	SHIP_TYPE: [
		{ bs_ship_type: "Bulk Carrier" },
		{ bs_ship_type: "Gas carrier," },
		{ bs_ship_type: "Tanker (Oil Tanker, Oil/Chemical Tanker, Chemical tanker)" },
		{ bs_ship_type: "Container Ship" },
		{ bs_ship_type: "General Cargo Ship" },
		{ bs_ship_type: "Refrigerated Cargo Carrier" },
		{ bs_ship_type: "Combination Carrier" },
		{ bs_ship_type: "LNG Carrier" },
		{ bs_ship_type: "Ro-Ro Ship (Vehicle Carrier, Cargo Ship, Passenger Ship)" },
		{ bs_ship_type: "Cruise Passenger Ship" },
		{ bs_ship_type: "Others" },
	],
	SUPPLY_MODE: [{ bs_mode_of_supply: "Truck" }, { bs_mode_of_supply: "Barge" }, { bs_mode_of_supply: "Direct from terminal" }],
	PORTS: [
		{ id: 3, bs_port_of_supply: "Alleppey" },
		{ id: 4, bs_port_of_supply: "Bedi" },
		{ id: 5, bs_port_of_supply: "Bhavnagar" },
		{ id: 6, bs_port_of_supply: "Bankot" },
		{ id: 7, bs_port_of_supply: "Mumbai" },
		{ id: 8, bs_port_of_supply: "Bulsar" },
		{ id: 9, bs_port_of_supply: "Kozhikode" },
		{ id: 10, bs_port_of_supply: "Kolkata" },
		{ id: 11, bs_port_of_supply: "Cuddalore" },
		{ id: 12, bs_port_of_supply: "Cochin" },
		{ id: 13, bs_port_of_supply: "Colochel" },
		{ id: 14, bs_port_of_supply: "Coondapur" },
		{ id: 15, bs_port_of_supply: "Dahej" },
		{ id: 16, bs_port_of_supply: "Dabholrt" },
		{ id: 17, bs_port_of_supply: "Dholera" },
		{ id: 18, bs_port_of_supply: "Haldia" },
		{ id: 19, bs_port_of_supply: "Honavar" },
		{ id: 20, bs_port_of_supply: "Hazira" },
		{ id: 21, bs_port_of_supply: "Mangalore" },
		{ id: 22, bs_port_of_supply: "Kandla" },
		{ id: 23, bs_port_of_supply: "PortBlair" },
		{ id: 24, bs_port_of_supply: "AndamanIS" },
		{ id: 25, bs_port_of_supply: "Janjira" },
		{ id: 26, bs_port_of_supply: "Jafradbad" },
		{ id: 27, bs_port_of_supply: "Jaitapur" },
		{ id: 28, bs_port_of_supply: "Kakinada" },
		{ id: 29, bs_port_of_supply: "Krishnapatanam" },
		{ id: 30, bs_port_of_supply: "Karaikal" },
		{ id: 31, bs_port_of_supply: "Karwar" },
		{ id: 32, bs_port_of_supply: "Chennai" },
		{ id: 66, bs_port_of_supply: "Kamarajar Port" },
		{ id: 67, bs_port_of_supply: "L&T Port Ltd" },
		{ id: 33, bs_port_of_supply: "Malpe" },
		{ id: 34, bs_port_of_supply: "Masulipatnam" },
		{ id: 35, bs_port_of_supply: "Magdalla" },
		{ id: 36, bs_port_of_supply: "MulDwarka" },
		{ id: 37, bs_port_of_supply: "Mandvi" },
		{ id: 38, bs_port_of_supply: "Maroli" },
		{ id: 39, bs_port_of_supply: "Marmagao" },
		{ id: 40, bs_port_of_supply: "Mundra" },
		{ id: 41, bs_port_of_supply: "Navlakhi" },
		{ id: 42, bs_port_of_supply: "Nindakara" },
		{ id: 43, bs_port_of_supply: "Nagappattinam" },
		{ id: 44, bs_port_of_supply: "Jawaharlal Nehru(NhavaSheva)" },
		{ id: 45, bs_port_of_supply: "Okha" },
		{ id: 46, bs_port_of_supply: "Panaji" },
		{ id: 47, bs_port_of_supply: "Goa" },
		{ id: 48, bs_port_of_supply: "Pipavav" },
		{ id: 49, bs_port_of_supply: "Porbandar" },
		{ id: 50, bs_port_of_supply: "Pamban" },
		{ id: 51, bs_port_of_supply: "Paradip" },
		{ id: 52, bs_port_of_supply: "Garh" },
		{ id: 53, bs_port_of_supply: "Rameshwaram" },
		{ id: 54, bs_port_of_supply: "Rajpuri" },
		{ id: 55, bs_port_of_supply: "Ratnagiri" },
		{ id: 56, bs_port_of_supply: "Revdanda" },
		{ id: 57, bs_port_of_supply: "Sikka" },
		{ id: 58, bs_port_of_supply: "Thiruvananthapuram" },
		{ id: 59, bs_port_of_supply: "Tuticorin" },
		{ id: 60, bs_port_of_supply: "Umbergaon" },
		{ id: 61, bs_port_of_supply: "Vadinar" },
		{ id: 62, bs_port_of_supply: "Vengurla" },
		{ id: 63, bs_port_of_supply: "Visakhapatnam" },
		{ id: 64, bs_port_of_supply: "Veraval" },
		{ id: 65, bs_port_of_supply: "Others" },

	],
	EPLAN_USER_ROLES_CATEGORY: {
		CLIENT: "Client",
		IRS: "IRS"
	},
	EPLAN_USER_ROLES: {
		ADMIN: 1,
		HOD: 2,
		REVIEWER: 3,
		SURVEYOR: 4,
		SURVEYSTATION: 5,
		SHIPYARD: 6,
		SHIPOWNER: 7,
		CONSULTANT: 8,
		OTHER: 9,
		SUPPLIER: 10,
		QA: 11,
		DESIGNER: 12,
		SURVEYSTATIONS: 13,
		SHIPMANAGER: 14,
		OTHERS: 15,
		BOATBUILDER: 16
	},
	ROR_LINK_URL: "https://eplanror.irclass.org/redirect",
	ROR_SIGNOFF_ENCR: "ROR_SIGNOFF_ENCR",
	BDN_STATUS_SAVED: 0,
	BDN_STATUS_SUBMITTED: 1,
	BDN_STATUS_SIGNED: 2,
	BDN_STATUS: [
		{value:"", name:"All"},
		{value: 0, name: "Saved" },
		{value: 1, name: "Submitted"},
		{value: 2, name :" Signed"}
	],
	IMO_NUMBER_LENGTH: 8,
	BDN_PREVIOUS_DATE_OF_DELIVERY_ALLOWED: 3,
	UTC_OFFSET: 0,
};

export default constants;
