import React from "react";
import { Box } from "@chakra-ui/react";

const content = (props) => {
	const { children, SideMenu, Header, Footer } = props;
	return (
		<Box bg="white.50" minHeight="100vh" h="100vh">
			{Header && <Header {...children.props} />}
			{SideMenu && <SideMenu {...children.props} />}
			<Box h="100%">
				{children}
			</Box>
			{/* <LoaderOverlay active={false} /> */}
			{Footer && <Footer {...children.props} />}
		</Box>
	);
};

export default content;