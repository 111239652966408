class User {
	set = (data) => {
		const { token, user_details, } = data;
		user_details.token = token;
		const encoded = Buffer(JSON.stringify(user_details)).toString("hex");
		window.localStorage.setItem("USER", encoded);
	}

	setClient = (data) => {
		const { token, user_details, is_admin_loggedin_as_client } = data;
		user_details.token = token;
		user_details.is_admin_loggedin_as_client = is_admin_loggedin_as_client;
		const encoded = Buffer(JSON.stringify(user_details)).toString("hex");
		window.localStorage.setItem("CLIENT_USER", encoded);
	}

	get = () => {
		const user = window.localStorage.getItem("CLIENT_USER") || window.localStorage.getItem("USER");
		if (user != null) {
			const decoded = Buffer.from(user, "hex");
			return JSON.parse(decoded);
		} else {
			return {};
		}
	}

	update = (data) => {
		const userInfo = this.get();
		data.token = userInfo.token;
		const encoded = Buffer(JSON.stringify(data)).toString("hex");
		window.localStorage.setItem("USER", encoded);
	}

	token = () => {

		const userInfo = this.get();
		return userInfo && userInfo.token;
	}

	userName = () => {
		const user = window.localStorage.getItem("CLIENT_USER") || window.localStorage.getItem("USER");
		if (user != null) {
			const decoded = Buffer.from(user, "hex");
			const decodedParsed = JSON.parse(decoded.toString());
			return `${decodedParsed.first_name} ${decodedParsed.last_name}`;
		} else {
			return null;
		}
	}

	loggedin() {
		return this.token() != null;
	}

	logout() {
		window.localStorage.clear();
		window.location.href = "/";
	}

	setField(key, value) {
		window.localStorage.setItem(key, value);
	}

	getField(key) {
		return window.localStorage.getItem(key);
	}

	removedClientUser() {
		window.localStorage.removeItem("CLIENT_USER");
	}
}

export default new User();