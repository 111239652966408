/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Box, Menu, MenuButton, MenuList, MenuItem, Text, Image, Tooltip } from "@chakra-ui/react";
import User from "../../helpers/current-user";
import styled from "@emotion/styled";
import { IoIosArrowDown } from "react-icons/io";
import DGShipping from "../../assets/images/logo/dg_shipping_logo_new.png";
import UserManual from "../../assets/images/icons/User_Manual.svg";
import { colors } from "../../helpers/theme";
import { Link } from "react-router-dom";

const Header = () => {
	const HeaderWrapper = styled(Box)`
		position: fixed;
		top: 0;
		left: 0;
		background: ${colors.white[900]};
		right: 0;
		z-index: 2;
		height: 60px;
	`;
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const user = User.get();
	const [notificationList] = useState([]);
	useEffect(() => {
		if (User.loggedin()) {
			setIsLoggedIn(true);
		}
	}, []);

	return isLoggedIn ? (
		<HeaderWrapper
			boxShadow="md"
			d="flex"
			justifyContent="end"
			alignItems="center"
			w={{ base: "100vw", lg: "82%" }}
			ml={{ base: "0", lg: "18%" }}
			p={3}>
			<Box display="flex" px={2}>
				<Menu>
					<MenuButton
						px={2}
						d={{ xs: "none", md: "inline-block" }}
						backgroundColor="transparent">
						<Box display="flex" alignItems="center">
							<Text color="violet.900" fontSize="lg" fontWeight="bold" pr={2}>
								{user.company_name}
							</Text>
							<IoIosArrowDown color="violet.900" w="10px" />
						</Box>
					</MenuButton>
					<MenuList>
						<MenuItem color="gray.700" as="a" onClick={User.logout}>
							Logout
						</MenuItem>
					</MenuList>
				</Menu>
				<Box height="30px" width="1px" bgColor="gray.500" mx={4}>
				</Box>
				<Link to={"/docs/User_Manual.pdf"} target="_blank" rel="noreferrer">
					<Tooltip label={"User Manual"} bg='gray.100' color="themeBlue.900">
						<Image height="30px" src={UserManual} alt="User Manual" />
					</Tooltip>
				</Link>
			</Box>
		</HeaderWrapper>
	) : (
		<HeaderWrapper
			display="flex"
			px={5}
			boxShadow="md"
			alignItems="center"
			justifyContent="space-between"
		>
			<Box>
				<Image w={"100%"} src={DGShipping} alt="IRClass" height="55px" />
			</Box>
			<Link to={"/docs/User_Manual.pdf"} target="_blank" rel="noreferrer">
				<Tooltip label={"User Manual"} bg='gray.100' color="themeBlue.900">
					<Image src={UserManual} alt="User Manual" height="38px" />
				</Tooltip>
			</Link>
		</HeaderWrapper>
	);
};

export default Header;
