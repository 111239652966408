import { UNAUTHENTICATED_PAGE_NAMES, unauthenticatedRoutes } from "./Unauthenticated";
import { AUTHENTICATED_PAGE_NAMES, authenticatedRoutes } from "./Authenticated";
import { Switch, Route, Redirect } from "react-router-dom";
import Layout from "../shared/layout/content";
import SideMenu from "../shared/layout/sidemenu";
import Header from "../shared/layout/header";
import Footer from "../shared/layout/footer";
import CurrentUser from "../helpers/current-user";

const LoginRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) => {
			if (CurrentUser.loggedin()) {
				const dashboardRoute = authenticatedRoutes.find(route => route.pageName === AUTHENTICATED_PAGE_NAMES.DASHBOARD).path;
				return <Redirect to={dashboardRoute} />;
			} else {
				return (
					<Layout Header={Header} Footer={Footer}>
						<Component {...props} />
					</Layout>
				);
			}
		}}
	/>
);

const PrivateRoute = ({ component: Component, permit = [], ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) => {
				if (!CurrentUser.loggedin()) {
					return <Redirect to={"/"} />;
				} else {
					return (
						<Layout SideMenu={SideMenu} Header={Header}>
							<Component {...props} />
						</Layout>
					);
				}
			}}
		/>
	);
};

const PublicRoute = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) => {
				return (
					<Layout Header={Header} Footer={Footer}>
						<Component {...props} />
					</Layout>
				);
			}}
		/>
	);
};

const routes = (
	<Switch>
		{unauthenticatedRoutes.map(route => (
			route.pageName === UNAUTHENTICATED_PAGE_NAMES.LOGIN || route.pageName === UNAUTHENTICATED_PAGE_NAMES.HOME ?
				<LoginRoute exact={route.exact} path={route.path} component={route.asyncComponent} />
				:
				<PublicRoute exact={route.exact} path={route.path} component={route.asyncComponent} />
		))}
		{authenticatedRoutes.map(route => (
			<PrivateRoute exact={route.exact} path={route.path} component={route.asyncComponent} />
		))}
	</Switch>
);

export default routes;