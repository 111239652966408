import { lazy } from "react";

export const AUTHENTICATED_PAGE_NAMES = {
	DASHBOARD: "DASHBOARD",
	ADD_BDN: "ADD_BDN",
	ADD_BDN_UPDATE: "ADD_BDN_UPDATE",
	USER_PROFILE: "USER_PROFILE",
	PREVIEW_BDN: "PREVIEW_BDN",
};

export const authenticatedRoutes = [
	{
		pageName: AUTHENTICATED_PAGE_NAMES.DASHBOARD,
		exact: true,
		path: "/dashboard",
		asyncComponent: lazy(() => import("../pages/dashboard")),
	},
	{
		pageName: AUTHENTICATED_PAGE_NAMES.ADD_BDN,
		exact: true,
		path: "/bdn-form",
		asyncComponent: lazy(() => import("../pages/add-bdn")),
	},
	{
		pageName: AUTHENTICATED_PAGE_NAMES.ADD_BDN_UPDATE,
		exact: true,
		path: "/bdn-form/:bsDetailId",
		asyncComponent: lazy(() => import("../pages/add-bdn")),
	},
	{
		pageName: AUTHENTICATED_PAGE_NAMES.USER_PROFILE,
		exact: true,
		path: "/profile",
		asyncComponent: lazy(() => import("../pages/user-profile")),
	},

	{
		pageName: AUTHENTICATED_PAGE_NAMES.PREVIEW_BDN,
		exact: true,
		path: "/preview-bdn",
		asyncComponent: lazy(() => import("../pages/preview-bdn")),
	},
	{
		pageName: AUTHENTICATED_PAGE_NAMES.PREVIEW_BDN,
		exact: true,
		path: "/preview-bdn/:bsDetailId",
		asyncComponent: lazy(() => import("../pages/preview-bdn")),
	}

];