export const colors = {
	blue: {
		50: "#EBF8FF",
		100: "#BEE3F8",
		200: "#90CDF4",
		300: "#63B3ED",
		400: "#4299E1",
		500: "#3182CE",
		600: "#2B6CB0",
		700: "#2C5282",
		800: "#2A4365",
		900: "#1A365D",
	},
	themeBlue: {
		50: "rgba(0,119,153,0.1)",
		100: "rgba(0,119,153,0.2)",
		200: "rgba(0,119,153,0.3)",
		300: "rgba(0,119,153,0.4)",
		400: "rgba(0,119,153,0.5)",
		500: "rgba(0,119,153,0.6)",
		600: "rgba(0,119,153,0.7)",
		700: "rgba(0,119,153,0.8)",
		800: "rgba(0,119,153,0.9)",
		900: "#007799",
	},
	violet: {
		50: "rgba(9,19,90,0.1)",
		100: "rgba(9,19,90,0.2)",
		200: "rgba(9,19,90,0.3)",
		300: "rgba(9,19,90,0.4)",
		400: "rgba(9,19,90,0.5)",
		500: "rgba(9,19,90,0.6)",
		600: "rgba(9,19,90,0.7)",
		700: "rgba(9,19,90,0.8)",
		800: "rgba(9,19,90,0.9)",
		900: "#09135A",
	},
	lightBlue: {
		100: "#f5f9ff",
		200: "#c7e6ef"
	},
	gray: {
		10: "#DEE2ED3D",
		50: "#F7FAFC",
		100: "#F3F3F6",
		200: "#E2E8F0",
		300: "#CBD5E0",
		400: "#A0AEC0",
		500: "#6A6A86",
		600: "#4A5568",
		700: "#2D3748",
		800: "#181824",
		900: "#171923",
		1000: "#231752"
	},
	green: {
		50: "#F0FFF4",
		100: "#1FAC8C",
		200: "#9AE6B4",
		300: "#68D391",
		400: "#48BB78",
		500: "#38A169",
		600: "#2F855A",
		700: "#276749",
		800: "#22543D",
		900: "#1C4532"
	},
	red: {
		50: "#FED7D7",
		100: "#EB5757",
		200: "rgba(229,62,62,0.2)",
		300: "rgba(229,62,62,0.3)",
		400: "rgba(229,62,62,0.4)",
		500: "#E53E3E",
		600: "#C53030",
		700: "#9B2C2C",
		800: "#822727",
		900: "#63171B"
	},
	yellow: {
		100: "rgba(233,180,48,0.15)",
		200: "rgba(233,180,48,0.25)",
		300: "rgba(233,180,48,0.35)",
		400: "rgba(233,180,48,0.45)",
		500: "rgba(233,180,48,0.55)",
		600: "rgba(233,180,48,0.65)",
		700: "rgba(233,180,48,0.75)",
		800: "rgba(233,180,48,0.85)",
		900: "rgb(233,180,48)",
	},
	white: {
		50: "rgba(255,255,255,0.15)",
		100: "rgba(255,255,255,0.25)",
		200: "rgba(255,255,255,0.35)",
		300: "rgba(255,255,255,0.45)",
		400: "rgba(255,255,255,0.55)",
		500: "rgba(255,255,255,0.65)",
		600: "rgba(255,255,255,0.75)",
		700: "rgba(255,255,255,0.85)",
		800: "rgba(255,255,255,0.95)",
		900: "rgb(255,255,255)"
	},
	purple: {
		100: "#E7E0FF"
	},
	black: {
		100: "#262626"
	},
	transparent: {
		100: "00FFFFFF"
	}
};
const fontWeights = {
	normal: 400,
	medium: 500,
	bold: 700
};
const textStyles = {
	h1: {
		fontSize: ["24px", "36px", "48px"],
		fontWeight: "bold"
	},
	h2: {
		fontSize: ["16px", "26px", "36px"],
		fontWeight: "bold"
	},
	h3: {
		fontSize: ["14px", "22px", "28px"],
		fontWeight: "bold"
	},
	h4: {
		fontSize: ["12px", "18px", "20px"],
		fontWeight: "bold"
	},
	h5: {
		fontSize: ["10px", "14px", "16px"]
	},
	navItem: {
		fontSize: [".8rem", ".9rem"],
		fontWeight: "normal",
		lineHeight: "1.25rem",
		letterSpacing: "0.02rem"
	},
	span: {
		fontSize: [".8rem", ".9rem"],
		fontWeight: "normal",
		letterSpacing: "0.01rem"
	}
};

const styles = {
	global: {
		"html,body": {
			fontFamily: "'IBM Plex Sans'",
			fontSize: "sm",
			color: "gray.500"
		},
		"*::placeholder": {
			color: "gray.300",
			fontSize: "0.8em"
		},
		a: {
			_focus: {
				boxShadow: "none !important"
			}
		}
	}
};

const theme = { colors, styles, textStyles, fontWeights };
export default theme;
