import { AUTHENTICATED_PAGE_NAMES, authenticatedRoutes } from "../../routes/Authenticated";
import dashboardIcon from "../../assets/images/icons/dashboard.png";
import dashboardWhiteIcon from "../../assets/images/icons/dashboard-white.png";
import userProfileWhite from "../../assets/images/icons/user-profile-white.svg";
import userProfileBlack from "../../assets/images/icons/user-profile-black.svg";

const sidemenuRoutes = [
	{
		id: 1,
		name: "Dashboard",
		isActive: true,
		icon: dashboardIcon,
		whiteIcon: dashboardWhiteIcon,
		to: authenticatedRoutes.find(route => route.pageName == AUTHENTICATED_PAGE_NAMES.DASHBOARD).path,
		showAdminLoggedinAsClient: true
	},
	{
		id: 2,
		name: "User Profile",
		isActive: true,
		icon: userProfileBlack,
		whiteIcon: userProfileWhite,
		to: authenticatedRoutes.find(route => route.pageName == AUTHENTICATED_PAGE_NAMES.USER_PROFILE).path,
		showAdminLoggedinAsClient: false
	}
];
export default sidemenuRoutes;