import { lazy } from "react";

export const UNAUTHENTICATED_PAGE_NAMES = {
	HOME: "HOME",
	LOGIN: "LOGIN",
	REGISTRATION: "REGISTRATION",
};

export const unauthenticatedRoutes = [
	{
		pageName: UNAUTHENTICATED_PAGE_NAMES.HOME,
		exact: true,
		path: "/",
		asyncComponent: lazy(() => import("../pages/login")),
	},
	{
		pageName: UNAUTHENTICATED_PAGE_NAMES.LOGIN,
		exact: false,
		path: "/login",
		asyncComponent: lazy(() => import("../pages/login")),
	},
	{
		pageName: UNAUTHENTICATED_PAGE_NAMES.REGISTRATION,
		exact: false,
		path: "/user-registration",
		asyncComponent: lazy(() => import("../pages/registration")),
	}

];