import React from "react";
import styled from "@emotion/styled";
import { Box, Image, Text } from "@chakra-ui/react";
import { colors } from "../../helpers/theme";
import IRClassLogo from "../../assets/images/logo/irclass_logo.png";

const footer = () => {
	const FooterWrapper = styled(Box)`
		position: fixed;
		bottom: 0;
		left: 0; 
		background:${colors.lightBlue[100]};
		right: 0;
		z-index: 2;
		height: 60px;
	`;

	return (
		<FooterWrapper
			d="flex"
			px={3}
			boxShadow="md"
			alignItems="center"
			justifyContent="end"
		>
			<Box d="flex" flexDirection="row" alignItems="center">
				<Text width="50%" mr={2}>Powered by</Text>
				<Image w={"100%"} src={IRClassLogo} alt="IRClass" height="40px"/>
			</Box>
		</FooterWrapper>
	);
};

export default footer;
