import React from "react";
import styled from "@emotion/styled";
import { colors } from "../../helpers/theme";
import { Image } from "@chakra-ui/react";
import BunkerLogo from "../../assets/images/logo/bunker_logo.png";


const LoaderWrapper = styled.div`
height:100vh;
display: flex;
position: fixed;
align-items: center;
justify-content: center;
top:0;
right:0;
left: 0;
z-index:4;
background:${colors.white["900"]};
img{
	width: 200px;
}
.loader {
	border: 5px solid ${colors.gray["50"]};
	border-radius: 50%;
	border-top: 5px solid ${colors.violet["900"]};
	width: 30px;
	height: 30px;
	animation: spin 2s linear infinite;
	margin: auto;
  }	
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}`;

const loaderOverlay = (props) => {
	return props.active && <Loader
		hideLogo={props.hideLogo}
	/>;
};

const Loader = (props) => {
	const { message = "" } = props;
	return (
		<LoaderWrapper>
			<div>
				{!props.hideLogo ?
					<><Image src={BunkerLogo} alt="Vector Image"/>
					</>
					: null}
				<div className="pt-3">
					<div className="loader"></div>
					<p>{message}</p>
				</div>
			</div>
		</LoaderWrapper>
	);
};

export default loaderOverlay;